'use client';
import Link from 'next/link';
import Image from 'next/image';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

export default function Error({ reset, error }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div>
      <div
        className={'d-flex align-items-center justify-content-center'}
        style={{
          height: '55px',
          backgroundColor: 'black',
          color: 'white',
          fontSize: '1.5rem',
        }}>
        <Image priority src="/intstatic/images/mbb-logo-white.svg" alt="mybestbrands logo" height={55} width={300} />
      </div>
      <div className={'container d-flex flex-column justify-content-center'} style={{ height: 'calc(100vh - 55px)' }}>
        <div className={'row mx-4 mx-md-0'}>
          <div className={'col-md-4 order-1 order-md-0'}>
            <Image
              src="/intstatic/images/ErrorBackground.jpg"
              alt="404"
              sizes="100vw"
              width={0}
              height={0}
              style={{
                width: '100%',
                height: 'auto',
                aspectRatio: '419/558',
              }}
            />
          </div>
          <div
            className={
              'col-md-8 order-0 order-md-1 d-flex flex-column text-center align-items-center justify-content-center gap-2 gap-md-5 mb-5 mb-md-0'
            }>
            <h1
              style={{
                fontFamily: 'var(--font-playfair)',
              }}>
              <i>Oops, something went wrong</i>
            </h1>
            <div className={'d-flex gap-2'}>
              <Link href={'/'} className={'btn btn-black-hover-light'}>
                Home
              </Link>
              <button className={'btn btn-white-hover-black'} onClick={() => reset()}>
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
